import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Item } from '../helper';

interface DropdownComponentProps {
    firstDropdownData: Item[];
    secondDropdownData: Item[];
    thirdDropdownData: Item[];
    setSelectedFirst: (item: Item) => void;
    setSelectedSecond: (item: Item) => void;
    setSelectedThird: (item: Item) => void;
    selectedFirst: any;
    selectedSecond: any;
    selectedThird: any;
}

const Dropdowns: React.FC<DropdownComponentProps> = ({
    firstDropdownData,
    secondDropdownData,
    thirdDropdownData,
    setSelectedFirst,
    setSelectedSecond,
    setSelectedThird,
    selectedFirst,
    selectedSecond,
    selectedThird
}) => {
    return (
        <div className="m-4 flex justify-content-between flex-wrap">
            <div className="flex align-items-center justify-content-center ">
                <Dropdown
                    value={selectedFirst}
                    options={firstDropdownData}
                    filter
                    optionLabel="name"
                    onChange={(e) => setSelectedFirst(e.value)}
                    placeholder="Select a Project"
                />
            </div>

            <div className="flex align-items-center justify-content-center">
                <Dropdown
                    value={selectedSecond}
                    options={secondDropdownData}
                    filter
                    optionLabel="name"
                    onChange={(e) => setSelectedSecond(e.value)}
                    placeholder="Select a Workpack"
                />

            </div>

            <div className="flex align-items-center justify-content-center">
                <Dropdown
                    value={selectedThird}
                    options={thirdDropdownData}
                    filter
                    optionLabel="name"
                    onChange={(e) => setSelectedThird(e.value)}
                    placeholder="Select a Task"
                />
            </div>
        </div>
    );
};

export default Dropdowns;