import React from 'react';

interface CustomTaskListHeaderProps {
  headerHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
}

const CustomTaskListHeader: React.FC<CustomTaskListHeaderProps> = ({
  headerHeight,
  rowWidth,
  fontFamily,
  fontSize,
}) => {
  const style = {
    height: `${headerHeight}px`,
    lineHeight: `${headerHeight}px`,
    width: rowWidth,
    fontFamily: fontFamily,
    fontSize: fontSize,
  };

  return (
    <div className="custom-task-list-header" style={{
      display: 'flex',
      flexDirection: 'row',
      height: headerHeight,
      lineHeight: `${headerHeight}px`,
      fontFamily: fontFamily,
      fontSize: fontSize,
    }}>
      <div className="header-item" 
        style={{ width: rowWidth, paddingLeft: 5 }}>Name</div>
      <div className="header-item" style={{ width: rowWidth, paddingLeft: 5 }}>Start Date</div>
      <div className="header-item" style={{ width: rowWidth, paddingLeft: 5 }}>End Date</div>
      <div className="header-item" style={{ width: rowWidth, paddingLeft: 5 }}>Earned Hrs / Duration</div>
    </div>
  );
};

export default CustomTaskListHeader;